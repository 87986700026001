<template>
  <div class="tag-panel">
    <div class="tag-group left" tabindex="-1">
      <span class="tag custom new-construction-tag" v-if="isNewConstruction"><span>New Construction</span></span>
      <span class="tag custom ready-to-build-tag" v-else-if="isReadyToBuild"><span>Ready To Build</span></span>
      <span class="tag custom new-tag" v-else-if="isNewTag">
        <span>{{ molang('search.card.newAgo') }}</span>
        <span>{{ property.createTimeDisplay.replace('Minutes', 'Min') }}</span>
      </span>
      <span class="tag custom sold-tag" v-else-if="isSoldTag">
        <span>{{ property.houseRealStatusDisplay }}</span>
        <span v-if="property.showSoldDate">{{ $filters.formatDate(property.soldDate, { year: '2-digit' }) }}</span>
      </span>
      <span class="tag custom open-tag" v-else-if="isOpenTag">
        <span>{{ molang('search.card.open') }}</span>
        <span>{{ property.openDateV1 }}</span>
      </span>
      <span class="tag custom pricecut-tag" v-else-if="isPriceCutTag">
        <span :class="property.isPriceReduced ? 'price-reduced' : 'price-increased'">
          <i :class="{
              'icon-arrow-down-circle-filled': property.isPriceReduced,
              'icon-send-ios': !property.isPriceReduced
            }"
          ></i>
          {{ $filters.formatFriendlyPrice(property.priceChange) }}
        </span>
      </span>
      <span class="tag custom hot-tag" v-else-if="isHotTag">
        <span>
          <img :src="glb.cdnUrl + 'vimages/fire-filled.svg'" />
          HOT
        </span>
      </span>
      <span class="tag custom listingby-movoto-tag" v-else-if="isListingByMovoto">
        <span>{{ molang('dpp.propertyNearby.listedBy') }}</span>
      </span>
    </div>

    <div class="tag-group right">
      <button
          v-if="showShare"
          type="button"
          aria-label="Share Property"
          @click="() => sendEmail(property)"
          class="share circle small bg-white"
      >
        <i class="icon-share-ios"></i>
      </button>
      <span
          v-if="showLeadButton"
          aria-label="select Property"
          class="circle small"
          :class="selectedCard ? 'bg-green' : 'bg-white'"
      >
        <i :class="{ 'icon-check': selectedCard }"></i>
      </span>
      <button
          v-else
          type="button"
          aria-label="Favorite Property"
          @click.stop="favoriteAction"
          class="favorite circle small bg-white"
          title="Save"
      >
        <i class="icon-heart-o" :class="{ active: isFavorite }"></i>
      </button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: 'PropertyCardTags',
  props: {
    property: Object,
    selectedCard: Boolean,
    showShare: Boolean,
    showLeadButton: Boolean,
    cardFilter: String,
  },
  inject: ['$eventBus'],
  methods: {
    ...mapActions('glb', ['updateFavorite']),
    favoriteAction() {
      const { propertyId, listingId, isPrOnly, formatMlsInfo, geo, mlsDescription, mlsLogoSrc, photoCount, tnImgPath } = this.property;
      const isFavorite = !this.favoriteListings.includes(propertyId);
      const options = {
        propertyData: { formatMlsInfo, geo, mlsDescription, mlsLogoSrc, photoCount, tnImgPath },
        propertyId,
        listingId,
        isPrOnly,
        isFavorite,
        trigger: this.$options.name,
        lite: true,
      };
      $.setStorage('pendingRequestName', 'addfavorite');
      $.setStorage('favoriteHome', JSON.stringify(options));

      this.updateFavorite(options).then((res) => {
        if (res?.status?.code === 0) {
          if (isFavorite) {
            this.$eventBus.$emit('dialog-center-tip', `<span class="text-bold">Success!</span> <span class="text-medium">Home saved.</span>`);
          }
          this.$emit('updateFavListing', isFavorite);

          const noLead = +$.getStorage('hotleadCount') === 0;
          const noPhone = !(this.glb.hotleadInfo.phone || this.glb.user.phone);
          if (isFavorite && noLead && noPhone && !this.glb.rentals) {
            this.$eventBus.$emit('eventbus-pop-schedule-lead');
          }
        }
      });
    },
    sendEmail(dpp) {
      const baseUrl = this.glb.appUrl + dpp.path;
      const propertyId = dpp.propertyId;
      const separator = this.glb.OS === 'iOS' ? '%26' : '%3F';
      let sms = `utm_campaign%3Ddppsharing${separator}utm_medium%3Dsms${separator}utm_source%3Ddpp`;
      const appendParam = (key, val) => {
        sms += `${separator}${key}%3D${val}`;
      };

      const userId = this.glb.user?.id;
      const anonymousId = $.getCookie('ajs_anonymous_id')?.replace(/"/g, '');
      const userRole = this.glb.user?.role;
      const influenceId = this.glb.influenceAgentId;

      if (userId) appendParam('utm_term', userId);
      else if (anonymousId) appendParam('utm_term', anonymousId);

      if (['COBROKER_AGENT', 'AGENT'].includes(userRole)) {
        appendParam('utm_content', userRole);
      }

      if (influenceId) appendParam('agentid', influenceId);

      const fullUrl = `${baseUrl}%3F${sms}`;

      if (this.glb.isMobile && navigator.share) {
        const shareUrl = unescape(fullUrl.replace(/%3F/g, '%26'));
        navigator
            .share({
              title: this.molang('dpp.mvtEmail.shareHome'),
              text: this.molang('dpp.mvtEmail.checkoutHome'),
              url: shareUrl,
            })
            .catch((e) => console.error(e.message));
      } else {
        const emailOpt = {
          propertyId,
          listingId: dpp.listingId,
          listingUrl: baseUrl,
          propertyData: dpp,
          showComment: false,
          comment: `I am interested in ${dpp.geo.formatAddress}.`,
          sms: `${this.glb.geo.formatAddress} ${fullUrl}`,
          dialogTitle: this.molang('dpp.mvtEmail.shareHome'),
          hideHotlead: dpp?.isRentals,
        };
        this.$eventBus.$emit('dialog-center', {
          name: 'mvtSendEmail',
          trigger: this.$options.name,
          opt: emailOpt,
        });
      }
    },
  },
  computed: {
    ...mapGetters('glb', ['glb', 'favoriteListings']),
    isFavorite() {
      return this.favoriteListings.includes(this.property.propertyId);
    },
    isNewConstruction() {
      const { nhsListingType } = this.property;
      return (this.cardFilter === 'newConstruction' && nhsListingType !== 'Ready To Build') || (!this.cardFilter && nhsListingType === 'New Construction');
    },
    isReadyToBuild() {
      return (!this.cardFilter || this.cardFilter === 'newConstruction') && this.property.nhsListingType === 'Ready To Build';
    },
    isNewTag() {
      return !this.cardFilter && this.property.isNew;
    },
    isSoldTag() {
      return this.property.isSold;
    },
    isOpenTag() {
      return this.cardFilter === 'openHouse' || (!this.cardFilter && this.property.openDate);
    },
    isPriceCutTag() {
      const { showPriceChange, hideDaysOnMarket, labelName } = this.property;
      const isSavedHomesPage = this.glb.pageType === 'vmymovoto_savedhomes';
      const isAUC = labelName === 'Active Under Contract';
      const hasPriceCut = showPriceChange && !hideDaysOnMarket && !(isSavedHomesPage && isAUC);
      return this.cardFilter === 'priceCut' || (!this.cardFilter && hasPriceCut);
    },
    isHotTag() {
      return !this.cardFilter && this.property.isHot;
    },
    isListingByMovoto() {
      return this.cardFilter === 'listedByMovoto' || (!this.cardFilter && this.property.listingByMovoto);
    },
  },
};
</script>
