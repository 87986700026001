<template>
    <div id="container" class="errorPageBody">
        <mvtHeader />
        <section class="inline large">
            <div class="grid xs-grid-cols-1 center fixed fixed-max-sm gap-3 text-center">
                <img :src="glb.cdnUrl+'images/desktop/errorpages/no-homes-found.png'">
                <h1 class="f1 text-bold">404 - Page Not Found</h1>
                <h3 class="f3 text-bold">
                    Let's get you on the right path of your<br />
                    real estate journey.
                </h3>
                <div class="grid xs-grid-cols-1 sm-grid-cols-2">
                    <a class="btn primary regular" :href="glb.appUrl + 'for-sale/'">Buy a Home</a>
                    <a class="btn primary regular" :href="glb.appUrl + 'what-is-your-home-worth/'">Sell Your Home</a>
                    <a class="btn primary regular" :href="glb.appUrl + 'rentals/'">Rent a Home</a>
                    <a class="btn primary regular" :href="glb.appUrl + 'faqs/'">Find Answers</a>
                </div>
            </div>
        </section>
        <helpCard />
        <section class="large" v-if="getShowList('location')">
            <div class="grid xs-grid-cols-1 gap-3 fixed fixed-max-sm">
                <h3 class="wrap f3 text-bold">
                    New Listings Near You
                </h3>
                <mvtLazyload ref="lazyloadNearby" :async="true" data-role="propertycard">
                    <mvtNearby type="location" @completed="completed" carousalType="new"></mvtNearby>
                </mvtLazyload>
            </div>
        </section>
        <mvtDialogCenter></mvtDialogCenter>
        <mvtFooter />
    </div>
</template>
<script src="./page-error.js"></script>