<template>
  <article
      class="mvt-cardproperty"
      :class="theme"
      @click="onCardClick($event)"
      v-ga:clickable="'card'"
      :data-loading="loading"
      :data-link-name="glb.pageType === 'vmapsearch' && property.isActive && property.threeDTourLink ? '3D Card' : 'Card'"
      :data-id="property.propertyId"
      :mark-id="'mark0_' + property.geo.lat + '_' + property.geo.lng"
      :data-provider="property.showProvidedBy && property.mlsName ? `${molang('search.card.providedBy')} ${property.mlsName}` : null"
  >
    <propertyCardSchema :data="property" v-if="showSchema" />
    <PropertyCardImage
        :property="property"
        :showStreetView="showStreetView"
        :fetchListingDetails="fetchListingDetails"
        :class="theme"
    />
    <PropertyCardInfo :property="property" :cardHref="cardHref" />
    <PropertyCardTags
        :property="property"
        :selectedCard="selectedCard"
        :showShare="showShare"
        :showLeadButton="showLeadButton"
        :cardFilter="cardFilter"
    />
  </article>
</template>
<script src="./mvt-cardproperty.js"></script>
