<template>
    <Schema :text="getSchema" />
    <Schema v-if="getOpenHouseSchema" :text="getOpenHouseSchema" />
</template>

<script>
import { mapGetters } from 'vuex';
import Schema from './json-schema.vue';

export default {
    name: 'PropertyCardSchema',
    props: {
      data: {
        type: Object,
        default: {}
      },
    },
    components: { Schema },
    computed: {
      ...mapGetters('glb', ['glb']),
        getSchema() {
          const formattedAddress = this.data.geo.formatAddress;
          const url = this.data.dppUrl;
          const price = this.data.price || 0;
          const availability = this.data.isActive ? 'http://schema.org/InStock' : 'https://schema.org/SoldOut';

          const singleFamilySchema = {
            "@context": "http://schema.org",
            "@type": "SingleFamilyResidence",
            "name": formattedAddress,
            url,
            "address": {
              "@type": "PostalAddress",
              "streetAddress": this.data.geo.address || '',
              "addressLocality": this.data.geo.city || '',
              "postalCode": this.data.geo.zipcode || '',
              "addressRegion": this.data.geo.state || '',
            },
          };

          const productSchema = {
              "@context": "http://schema.org",
              "@type": "Product",
              name: formattedAddress,
              offers: {
                  "@type": "Offer",
                  priceCurrency: "USD",
                  availability,
                  price,
                  url,
              },
              description: formattedAddress,
          };

          if (this.data.geo.lat && this.data.geo.lng) {
            singleFamilySchema.geo = {
              "@type": "GeoCoordinates",
              "latitude": this.data.geo.lat,
              "longitude": this.data.geo.lng
            }
          }

          singleFamilySchema.image = productSchema.image = this.data.photoCount ? this.data.tnImgPath : this.glb.cdnUrl + 'images/nophoto_small.svg';

          return JSON.stringify([singleFamilySchema, productSchema]);
        },

        hideSchema() {
          return ['OH','KY'].indexOf(this.data.geo.state) > -1
        },
        getOpenHouseSchema() {
          if (!(this.data.openHouses && this.data.openHouses.length) || this.hideSchema) {
            return false;
          }

          const openHouse = this.data.openHouses[0];
          const formattedAddress = this.data.geo.formatAddress;
          const url = this.data.dppUrl;
          const price = this.data.price;

          const schema = {
            "@context": "http://schema.org",
            "@type": "Event",
            "name": this.data.appSeoJsonOpenHouse.name,
            url,
            "startDate": `${openHouse.date}T${openHouse.startTime}Z`,
            "endDate": `${openHouse.date}T${openHouse.endTime}Z`,
            "location": {
                "@type": "Place",
                "name": formattedAddress,
                "address": {
                    "@type": "PostalAddress",
                    "streetAddress": this.data.geo.address || '',
                    "addressLocality": this.data.geo.city || '',
                    "postalCode": this.data.geo.zipcode || '',
                    "addressRegion": this.data.geo.state || '',
                }
            },
            "description": `Open house at ${formattedAddress}`,
            "performer": {
                "@type": this.data.listingAgent ? 'Person': 'PerformingGroup',
                "name": this.data.listingAgent || this.data.officeListName,
            },
            "eventAttendanceMode": "https://schema.org/MixedEventAttendanceMode",
            "eventStatus": "https://schema.org/EventScheduled"
          }

          if(this.data.photoCount) {
            schema.image = this.data.tnImgPath;
          }

          if (this.data.geo.lat && this.data.geo.lng) {
            schema.location.geo = {
              "@type": "GeoCoordinates",
              "latitude": this.data.geo.lat,
              "longitude": this.data.geo.lng,
            }
          }

          if (price && this.data.isActive ) {
            schema.offers = {
                "@type": "Offer",
                "validFrom": `${openHouse.date}T${openHouse.startTime}Z`,
                "availability": "http://schema.org/InStock",
                "priceCurrency": "USD",
                url,
                price,
            };
          }

          return JSON.stringify(schema);
        }
    },
};
</script>
