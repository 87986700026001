import { mapGetters, mapMutations, mapActions } from 'vuex';

import propertyCardSchema from '@/common/components/schemas/property-card-schema.vue';
import { processPhotos } from '../../../msp/components/reels-view/photomapper.js';

// New Subcomponents
import PropertyCardImage from './mvt-cardproperty-image.vue';
import PropertyCardInfo from './mvt-cardproperty-info.vue';
import PropertyCardTags from './mvt-cardproperty-tags.vue';

export default {
    name: 'mvtCardProperty',
    components: {
        propertyCardSchema,
        PropertyCardImage,
        PropertyCardInfo,
        PropertyCardTags,
    },
    inject: ['$eventBus', 'split'],
    props: {
        preload: Boolean,
        tracked: Boolean,
        data: { type: Object, required: true },
        showStreetView: Boolean,
        openWindow: Boolean,
        showShare: Boolean,
        showLeadButton: Boolean,
        query: String,
        showSchema: Boolean,
        theme: { type: String, default: 'style1' },
        cardFilter: String,
    },
    data() {
        return {
            staticMapData: { width: 320, height: 200 },
            selectedCard: null,
            listingDetails: null,
            propertyDetails: null,
            photoRequestFinished: false,
            loading: null,
        };
    },
    computed: {
        ...mapGetters('glb', ['glb', 'viewedListings', 'favoriteListings', 'getSplit']),
        property() {
            return this.propertyDetails || this.data;
        },
        cardHref() {
            return this.query ? `${this.property.dppUrl}?${this.query}` : this.property.dppUrl;
        },
        isLight() {
            return this.data.light && this.data.propertyId;
        },
    },
    beforeMount() {
        this.fetchPropertyDetails();
    },
    created() {
        if (this.options) {
            this.componentName = this.options.type;
        }
    },
    methods: {
        ...mapActions('glb', ['updateFavorite', 'getDppPotoByUrl', 'getPropertyInfoById']),
        ...mapMutations('glb', ['addViewedPropertyId']),
        async fetchListingDetails() {
            const { listingId: id, path: url, photoCount } = this.property;
            if (this.listingDetails?.id === id || photoCount <= 1) return null;

            try {
                const dppPhoto = await this.getDppPotoByUrl({ url });
                const photos = processPhotos(dppPhoto);
                return photos.length > 0 ? photos.map((p) => p.url) : null;
            } catch (err) {
                console.error(`Error fetching photos for property: ${id}`, err);
                return null;
            }
        },
        onCardClick(event) {
            event.preventDefault();

            const property = this.property;

            if (this.showLeadButton) {
                this.selectedCard = property;
                setTimeout(() => {
                    this.$eventBus.$emit('send-lead', this.property);
                }, 500);
            } else {
                this.$emit('selectItem', property.propertyId, property);
                this.addViewedPropertyId(property.propertyId);

                // Open the new tab immediately to avoid popup blockers,
                // but defer with a microtask to ensure it's post-user input
                queueMicrotask(() => {
                    window.open(this.cardHref, '_blank');
                });
            }
        },
        fetchPropertyDetails() {
            if (!this.isLight) return;
            this.loading = true;
            this.getPropertyInfoById({ propertyIds: [this.property.propertyId] }).then((res) => {
                if (res?.[0]) this.propertyDetails = res[0];
                this.loading = null;
            });
        },
    },
};
