<template>
  <div class="mvt-cardproperty-photo" :data-mls="property.formatMlsInfo || null">
    <mvtMapStatic
        v-if="showStreetView && property.photoCount === 0"
        type="streetview"
        streetViewDefautType="satellite"
        :lat="property.geo.lat"
        :lng="property.geo.lng"
        :address="property.geo.formatAddress"
        :title="property.geo.formatAddress"
        :alt="property.geo.formatAddress"
        :width="staticMapData.width"
        :height="staticMapData.height"
        :cacheFirst="true"
    />
    <div v-else :data-alt="property.photoCount > 0 ? null : 'Photos coming soon'">
      <img
          loading="lazy"
          :src="property.tnImgPath"
          :alt="property.photoCount > 0 ? property.geo.formatAddress : 'Photos coming soon'"
      />
    </div>
    <button
        class="btn-3d"
        type="button"
        v-if="glb.pageType === 'vmapsearch' && property.isActive && property.threeDTourLink"
        @click.stop="$emit('openDpp')"
        data-link-name="3D"
    >
      <img loading="lazy" :src="glb.cdnUrl + 'vimages/home_animate.svg'" alt="3D Tour" />
    </button>
  </div>
</template>

<script>
import mvtMapStatic from '@/common/components/mvtmapstatic/mvt-mapstatic.vue';
import {mapGetters} from "vuex";

export default {
  name: 'PropertyCardImage',
  props: ['property', 'showStreetView', 'fetchListingDetails', 'staticMapData', 'theme'],
  components: {
    mvtMapStatic
  },
  computed: {
    ...mapGetters('glb', ['glb']),
  },
};
</script>
