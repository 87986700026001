import { mapGetters } from 'vuex';
import mvtNearby from '@/common/components/mvtnearby/mvt-nearby.vue';
import helpCard from '../helpcard/help-card.vue';
import lazyCarouselCheckMixin from '@/common/mixins/lazycarouselcheck';
import mvtDialogCenter from '@/common/components/mvtdialogcenter/mvt-dialogcenter.vue';
import mvtHeader from '@/common/components/mvtheader/mvt-header.vue';
import mvtFooter from '@/common/components/mvtfooter/mvt-footer.vue';
import mvtLazyload from '@/common/components/mvtlazyload/mvt-lazyload.vue';

export default {
    name: 'vError',
    inject: ['$eventBus'],
    components: {
        mvtHeader,
        mvtFooter,
        mvtDialogCenter,
        mvtNearby,
        helpCard,
        mvtLazyload,
    },
    mixins: [lazyCarouselCheckMixin],
    computed: {
        ...mapGetters('glb', ['glb']),
    },
    methods: {
        completed() {
            this.$refs.lazyloadNearby.ready();
        },
    },
    data: function () {
        return {
            searchInfo: {
                text: '',
                geo: null,
            },
        };
    },
    watch: {
        searchInfo(newVal) {
            if (newVal) {
                this.$eventBus.$emit('eventbus-search', {
                    text: newVal.text,
                    geo: newVal.geo,
                    isGoogleGeo: newVal.isGoogleGeo,
                    isMlsNumber: newVal.isMlsNumber,
                });
            }
        },
    },
    mounted() {
        this.$eventBus.$emit('eventbus-pageLoaded');
    },
    beforeUnmount() {},
};
