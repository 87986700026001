import mvtShifter from '@/common/components/mvtshifter/mvt-shifter.vue';
import mvtCardProperty from '@/common/components/mvtcardproperty/mvt-cardproperty.vue';

import { mapGetters } from 'vuex';

export default {
    name: 'mvtListProperty',
    components: {
        mvtShifter,
        mvtCardProperty
    },
    props: {
        data: {
            type: Array,
            required: true,
            default() {
                return [];
            }
        },
        tracked: {
            type: Boolean,
            default: true,
        },
        query: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        },
        cardFilter: {
            type: String,
            default: ''
        },
        showLeadButton: {
            type: Boolean,
            default: false,
        },
    },
    inject: ['$eventBus'],
    updated(){
        var that =this;
        this.$nextTick(function () {
            that.$refs.shifter && that.$refs.shifter.refresh();
        })
    },
    methods: {
        selectItem(propertyId, data) {
            this.$emit('selectItem', propertyId, data);
        },
        scroll(){
            this.$emit('scroll');
        },
    },
    computed: {
        ...mapGetters('glb', ['glb']),
        list(){
            return this.data;
        }
    }
};
