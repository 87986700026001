export const processPhotos = (categorizedPhotos) => {
    if (!categorizedPhotos) {
        return [];
    }
    let photos = [];
    if (categorizedPhotos.length > 1) {
        // if there are multiple categories, append the a predetermined number of photos from each for good variety
        [
            { type: 'Exterior', count: 2 },
            { type: 'Living', count: 1 },
            { type: 'Bedroom', count: 2 },
            { type: 'Bath', count: 1 },
            { type: 'Kitchen', count: 2 },
        ].forEach(({ type, count }) => {
            const category = categorizedPhotos.find((cat) => cat.tag === type);
            if (category && category.photos && category.photos.length > 0) {
                photos = photos.concat(category.photos.slice(0, count));
            }
        });
    } else {
        categorizedPhotos.forEach((c) => {
            if (c.photos) {
                photos = photos.concat(c.photos);
            }
        });
    }
    if (photos.length >= 80) {
        photos = photos.filter((_p, i) => i % 10 === 0);
    } else if (photos.length >= 40) {
        photos = photos.filter((_p, i) => i % 5 === 0);
    } else if (photos.length > 8) {
        photos = photos.slice(0, 8);
    }
    return photos;
};
