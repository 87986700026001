<template>
  <div class="mvt-cardproperty-info">
    <div>
      <a :href="cardHref" target="_blank" @click.stop="$emit('openDpp')" tabindex="-1">
        <address>
          <span>{{ property.geo.address }}</span>
          <span>{{ getSubAddress }}</span>
        </address>
      </a>
      <sub v-if="getHouseCardTitleText" v-html="getHouseCardTitleText"></sub>
      <img
          v-if="property.mlsLogoSrc && shouldShowMLSLogo"
          loading="lazy"
          class="img-mls"
          :alt="property.mlsDescription"
          :src="property.mlsLogoSrc"
      />
    </div>
    <ul :data-viewed="isViewed">
      <li class="price">
        {{ $filters.formatPrice(property.price) }}
        <abbr class="f5" title="Monthly Rent" v-if="property.isRentals">/Mo</abbr>
        <abbr title="Estimate Value" v-if="property.isEstPrice"> Est.</abbr>
      </li>
      <template v-if="!property.isLand">
        <li>
          {{ property.bed || '—' }}<abbr title="Bedroom">Bd</abbr>
        </li>
        <li>
          {{ property.bath || '—' }}<abbr title="Bathroom">Ba</abbr>
        </li>
      </template>
      <li v-if="property.area">
        {{ $filters.formatArea(property.area, property.isLand) }}
        <abbr :title="property.areaUnit">{{property.isLand ? 'Acre' : 'Sq Ft'}}</abbr>
      </li>
      <template v-if="!property.isRentals">
        <li class="sub" v-if="(property.isLand || property.isCondo) && property.pricePerArea">
          {{ $filters.formatPricePerArea(property.pricePerArea, property.isLand) }}
        </li>
        <li class="sub" v-else-if="property.lotSize">
          {{ $filters.formatFriendlyLotSize(property.lotSize, undefined, undefined, 'Sq Ft')}}
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import motime from '@/common/utilities/motime';

export default {
  name: 'PropertyCardInfo',
  props: {
    property: Object,
    cardHref: String,
  },
  methods: {
    ...mapGetters('glb', ['getComplianceMethod']),
    shouldShowMLSLogo() {
      const compliance = this.getComplianceMethod()();
      return compliance[0]?.rule.indexOf('hidePropertyCardLogo') === -1;
    },
    gethouseCardIconText(data) {
      let text = `<i class="${data.propertyTypeIcon}" title="${data.propertyTypeDisplayName}"></i>`;
      const { houseRealStatus, listDate, soldDate, isCommingSoonListing } = data;

      if (isCommingSoonListing && houseRealStatus === 'ACTIVE' && listDate !== '—') {
        text += ` ${this.molang('search.card.comingSoon')} ${motime.formatDate(listDate, { day: 'numeric', month: 'numeric', year: '2-digit' })}`;
      } else if (houseRealStatus === 'ACTIVE') {
        text += ` ${this.molang('search.card.forSale')}`;
      } else if (houseRealStatus === 'PENDING') {
        text += ` ${this.molang('search.card.underContract')}`;
      } else if (houseRealStatus === 'SOLD') {
        text += soldDate ? ` Sold on ${motime.formatDate(soldDate)}` : ' Recently Sold';
      } else if (houseRealStatus === 'FOR_RENT') {
        text += ` ${this.molang('search.card.forRent')}`;
      }
      return text;
    },
  },
  computed: {
    ...mapGetters('glb', ['viewedListings']),
    getSubAddress() {
      const { city, state, zipcode } = this.property.geo;
      return `${city}, ${state} ${zipcode}`;
    },
    getHouseCardTitleText() {
      return this.property.nhsListingType || this.gethouseCardIconText(this.property);
    },
    isViewed() {
      return this.property && !this.property.hideIsViewed && this.viewedListings.includes(this.property.propertyId);
    },
  },
};
</script>
